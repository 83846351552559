import * as React from 'react'
import styled from '@emotion/styled'
import { Script } from 'gatsby'
import Layout from '../components/layout'
import { Seo } from '../components/seo'
import {StaticImage} from "gatsby-plugin-image";
// import ResponsiveEmbed from "react-responsive-embed"

// import JotFormReact from "jotform-react"

const LocationPage = () => {
  const Fc = styled.div`
    width: 100%;
    overflow: hidden;
    max-height: calc(100vh - 100px);
    margin-bottom: var(--space-4);
    
  `

  const Sc = styled.div`
    margin-bottom: var(--space-4);

    h1 {
      margin-bottom: var(--space-1);
    }
    p {
      margin-top: 0;
      padding-top: 0;
    }
  `

  const Tc = styled.div`
    max-width: var(--size-1920);
    margin: 0 auto;
    .boxA,
    .boxB {
      display: flex;
      justify-content: center;
    }

    @media (min-width: 1130px) {
      .inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  `

  const IFrameContainer = styled.div`
    margin-top: var(--space-4);
    margin-bottom: var(--space-4);

    .iframe {
      //min-height: 800px;
      width: 100%;
      border: none;
    }
  `

  return (
    <Layout>
      <Fc>
        <StaticImage src={'../images/coal-harbour-location.jpg'} alt={'coal harbout liquore store location image'} layout="fullWidth" />

      </Fc>
      <Sc>
        <h1>LOCATION</h1>
      </Sc>
      <Tc>
        <div className="inner">
          <div className="boxA">
            <div>
              <h2>Coal Harbour Liquor Store</h2>
              <p>
                1218 W Pender St.
                <br />
                Vancouver, BC V6E 2F8
                <br />
                Phone: (604) 685-1212
                <br />
                <br />
                Hours Daily 10:00am - 11:00pm
                <br />
                <br />
                Corporate Delivery:
                <br />
                Tuesday - Friday 12:00pm - 4:30pm
              </p>
            </div>
          </div>
          <IFrameContainer>
          <iframe
  src="https://link.rawmedia.ca/widget/form/ZAmZWHQmtRawkJYAItjv"
  style={{width: "100%", height: "100%", border: "none", borderRadius: "4px"}}
  id="inline-ZAmZWHQmtRawkJYAItjv" 
  data-layout="{'id':'INLINE'}"
  data-trigger-type="alwaysShow"
  data-trigger-value=""
  data-activation-type="alwaysActivated"
  data-activation-value=""
  data-deactivation-type="neverDeactivate"
  data-deactivation-value=""
  data-form-name="Contact Us"
  data-height="924"
  data-layout-iframe-id="inline-ZAmZWHQmtRawkJYAItjv"
  data-form-id="ZAmZWHQmtRawkJYAItjv"
  title="Contact Us"
/>
          <Script src="https://link.rawmedia.ca/js/form_embed.js" />
          </IFrameContainer>
        </div>
      </Tc>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
  return (
    <>
      <Seo
        title="Coal Harbour Liquor Location"
        description="Coal Harbour Liquor store location"
        canonical="https://coalharbourliquorstore.com/location/"
      />
      <script src="https://link.rawmedia.ca/js/form_embed.js" />
    </>
  )
}

export default LocationPage
